<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>班级或小组：</span>
        <!-- <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item,idx) in myClasses" :key="idx" filterable
                     :label="item.upname+'-'+item.name+(item.type=='bj'?'[班级]':'[小组]')" :value="item.id"></el-option>
        </el-select> -->
        <tchtree v-model="class_id" @change="getData"/>
        <span style="margin-left:20px">选择周：</span>
        <el-date-picker @change="changeDate" size="small" v-model="year_week" type="week" :format="showWeek"
                        :firstDayOfWeek="1"></el-date-picker>
        <el-button type="primary" size="mini" icon="el-icon-download" @click="exportTZ" style="margin-left: 10px;">导出</el-button>
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>教师台账</el-breadcrumb-item>
          <el-breadcrumb-item>完成率统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <el-table v-tableFit :data="tableData" style="width: 100%;margin-bottom: 20px;" border show-summary>
        <el-table-column prop="yx_name" label="系部" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.yx_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tj_count" label="已完成" width="" align="center"></el-table-column>
        <el-table-column prop="no_count" label="未完成" width="" align="center"></el-table-column>
        <el-table-column prop="rate" label="完成率" width="" align="center"></el-table-column>
      </el-table>
      <div id="chartBox" style="height:600px"></div>

    </div>



  </div>
</template>

<script>
import util from "@/utils/util";
import tchtree from "@/views/com/tchTree.vue";
import {njsyxx_statistics_export_excel} from "../../../../../public/js/Export2Excel";

export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: '',
      sign_date: util.formatDate(new Date()),
      tableData: [],
      yxTableData: [],
      year_week: '',
      report_year: '',
      week: '',
      weekStart: "",
      weekEnd: "",
    }
  },
  mounted() {
    // $("#chartBox").height($(window).height() - 220)
    this.getMyClasses()
    this.getNowDate()
  },
  computed: {
    showWeek() {
      return `${this.report_year} 第 ${this.week} 周`
    },
  },
  methods: {
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        // this.class_id = this.myClasses[0].id
        this.getData()
      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.total_name == '合计') {
        return 'primary-row';
      }
      return '';
    },
    getData() {
      let results = []

      this.$http.post("/api/tch_tz_statistics", {
        class_id: this.class_id,
        year: this.report_year,
        week: this.week
      }).then(res => {
        res.data && res.data.map(a => {
          a.rate = this.$keepTwoDecimal(parseFloat(a.tj_count/a.qb_count) * 100).toFixed(0) + '%'
          a.no_count = a.qb_count - a.tj_count
        })
        this.tableData = res.data
        this.initChart()
      })
    },
    initChart() {
      let dataList = JSON.parse(JSON.stringify(this.tableData))
      console.log(dataList)
      dataList.map(e => { e.rate = parseFloat(e.rate.replace('%', '')) })
      //console.log(111, this.dataList)
      let dataAxis = this._lo.map(dataList, 'yx_name')
      let data = this._lo.map(dataList, 'rate')


      let option = {
        title: {
          text: '完成率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (a, b, c) {

            return dataAxis[a[0].dataIndex] + "<br/>完成率：" + a[0].value + "%"
          }
        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          splitLine: {
            show: false
          },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
            },
          }
        ],
      };


      let myChart = this.$echarts.init(document.getElementById('chartBox'));
      myChart.setOption(option)
    },
    getNowDate() {
      this.$http.post("/api/find_date").then(d => {
        this.report_year = d.data.year
        this.week = d.data.week
        this.year_week = new Date()
        this.weekStart = d.data.onMonday.split(' ')[0]
        this.weekEnd = d.data.sunday.split(' ')[0]

        this.getData()
        this.getData1()
      })
    },
    changeDate(e) {

      let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW")
      this.weekStart = this.$moment(this.year_week).utcOffset(480).format("yyyy-MM-DD")
      this.weekEnd = this.$moment(this.year_week).add(6, "day").utcOffset(480).format("yyyy-MM-DD")

      let arr = str.split('-')
      this.report_year = arr[0]
      this.week = arr[1]
      /*let weekStart = this.$moment(e).format("YYYY-MM-DD")
      this.week = datejs.getWeekNumber(weekStart)
      this.report_year = this.$moment(weekStart).endOf('isoWeek').format('Y')*/
      this.getData()
      this.getData1()
    },
    exportTZ() {
      this.$http.post('/api/export_statistics_xls', {
        class_id:this.class_id,
        year: this.report_year,
        week: this.week,
        type: 'tz'
      }).then(res => {
        let excelData = [
          [`${this.report_year}年第${this.week}周台账完成情况`],
          ['序号','系部','已完成','未完成','完成率']
        ]
        res.data && res.data.map((a,index) => {
          a.rate = this.$keepTwoDecimal(parseFloat(a.tj_count/a.qb_count) * 100).toFixed(0) + '%'
          a.no_count = a.qb_count - a.tj_count
          excelData.push([
            ++index,
            a.yx_name,
            a.tj_count,
            a.no_count,
            a.rate,
          ])
        })
        njsyxx_statistics_export_excel(excelData,5)
      })
    },
  },

};
</script>

