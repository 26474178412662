<template>
    <div class="cbox datatj">
        <el-row :gutter="20" style="height: 100%;">
            <el-col :span="4">
                <div style="height:10px"></div>
                <el-card size="small">
                    <div slot="header">
                        考评数据
                    </div>
                    <div>
                        <div :class="['dataitem', activeTab == '打卡统计' ? 'itemckd' : '']" @click="changeTab('打卡统计')">
                            打卡统计
                        </div>
                        <div :class="['dataitem', activeTab == '实习统计' ? 'itemckd' : '']" @click="changeTab('实习统计')">
                            实习统计
                        </div>
                        <div :class="['dataitem', activeTab == '周报统计' ? 'itemckd' : '']" @click="changeTab('周报统计')">
                            周报统计
                        </div>
                        <div :class="['dataitem', activeTab == '月报统计' ? 'itemckd' : '']" @click="changeTab('月报统计')">
                            月报统计
                        </div>
                        <div :class="['dataitem', activeTab == '巡访统计' ? 'itemckd' : '']" @click="changeTab('巡访统计')">
                            巡访统计
                        </div>
                        <div v-if="$store.state.pmid == 214" :class="['dataitem', activeTab == '单位考评统计' ? 'itemckd' : '']" @click="changeTab('单位考评统计')">
                          单位考评统计
                        </div>
                        <div v-if="$store.state.pmid == 221" :class="['dataitem', activeTab == '台账统计' ? 'itemckd' : '']" @click="changeTab('台账统计')">
                          台账统计
                        </div>
                    </div>
                </el-card>
                <div style="height:20px"></div>
                <el-card>
                    <div slot="header">
                        实习数据
                    </div>
                    <div>
                        <div :class="['dataitem', activeTab == '实习情况统计' ? 'itemckd' : '']" @click="changeTab('实习情况统计')">
                            实习情况统计
                        </div>
                        <div :class="['dataitem', activeTab == '地区分布' ? 'itemckd' : '']" @click="changeTab('地区分布')">
                            地区分布
                        </div>
                        <div :class="['dataitem', activeTab == '薪资范围' ? 'itemckd' : '']" @click="changeTab('薪资范围')">
                            薪资范围
                        </div>
                        <div :class="['dataitem', activeTab == '对口率' ? 'itemckd' : '']" @click="changeTab('对口率')">
                            对口率
                        </div>
                        <div :class="['dataitem', activeTab == '保险购买' ? 'itemckd' : '']" @click="changeTab('保险购买')">
                            保险购买
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="20" style="height: 100%;">
                <daka v-if="activeTab == '打卡统计'"></daka>
                <shixi v-if="activeTab == '实习统计'"></shixi>
                <zhoubao v-if="activeTab == '周报统计'"></zhoubao>
                <yuebao v-if="activeTab == '月报统计'"></yuebao>
                <xunfang v-if="activeTab == '巡访统计'" style="height: 100%;"></xunfang>
                <dwkp v-if="activeTab == '单位考评统计'"></dwkp>
                <ledger v-if="activeTab == '台账统计'"></ledger>

                <shixiStation v-if="activeTab == '实习情况统计'"></shixiStation>
                <diqu v-if="activeTab == '地区分布'"></diqu>
                <xinzi v-if="activeTab == '薪资范围'"></xinzi>
                <duikou v-if="activeTab == '对口率'"></duikou>
                <baoxian v-if="activeTab == '保险购买'"></baoxian>
            </el-col>
        </el-row>



    </div>
</template>
  
<script>
import shixiStation from "./com/shixiStation.vue"
import diqu from "./com/diqu.vue"
import xinzi from "./com/xinzi.vue"
import duikou from "./com/duikou.vue"
import baoxian from "./com/baoxian.vue"
import dwkp from "@/views/datatj/com/dwkp.vue";
import ledger from "@/views/datatj/com/wxjd/ledger.vue";
export default {
    components: {
        daka: () => {
            if (window.pmid == 211) {
                return import("./com/ngz/daka_ngz.vue")
            } else {
                return import("./com/daka.vue")
            }
        }, shixi: () => {
            if (window.pmid == 211) { return import("./com/ngz/shixi_ngz.vue") }
            else { return import("./com/shixi.vue") }
        }, zhoubao: () => {
            if (window.pmid == 211) { return import("./com/ngz/zhoubao_ngz.vue") }
            else { return import("./com/zhoubao.vue") }
        }, yuebao: () => {
            if (window.pmid == 211) { return import("./com/ngz/yuebao_ngz.vue") }
            else { return import("./com/yuebao.vue") }
        }, xunfang: () => {
            if (window.pmid == 211) { return import("./com/ngz/xunfang_ngz.vue") }
            else { return import("./com/xunfang.vue") }
        },
        shixiStation, diqu, xinzi, duikou, baoxian,dwkp,ledger
    },
    data() {
        return {
            activeTab: "打卡统计"
        }
    },
    mounted() {

    },
    methods: {
        changeTab(val) {
            console.log(111, val)
            // this.activeTab = $(e.target).text().trim()
            this.activeTab = val

            // console.log(this.activeTab)
        }
    },

};
</script>

<style lang="less">
.datatj {
    .el-card__body {
        padding: 10px !important;
    }
}
</style>
<style scoped>
.dataitem {
    line-height: 30px;
    cursor: pointer;
    color: cornflowerblue;
    padding: 0 10px;
    border-radius: 4px;
}

.dataitem:hover {
    background-color: #eee;
}

.itemckd {
    background-color: dodgerblue;
    color: #fff;
}

.itemckd:hover {
    background-color: dodgerblue;
}
</style>