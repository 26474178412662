<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>班级或小组：</span>
        <!-- <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item,idx) in myClasses" :key="idx" filterable
                     :label="item.upname+'-'+item.name+(item.type=='bj'?'[班级]':'[小组]')" :value="item.id"></el-option>
        </el-select> -->
        <tchtree v-model="class_id" @change="getData"></tchtree>
        <el-button v-if="$store.state.pmid == 221" type="primary" size="mini" icon="el-icon-download" @click="exportSXQK">导出</el-button>

      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生实习</el-breadcrumb-item>
          <el-breadcrumb-item>实习情况统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <el-table v-tableFit :data="tableData" style="width: 100%;margin-bottom: 20px;" height="50vh" border
        :row-class-name="tableRowClassName">

        <el-table-column prop="stu_name" label="班级" width="200">
          <template slot-scope="scope">
            <span :style="scope.row.total_name == '合计' ? 'color:#3469f3' : ''">{{ scope.row.yx_name }}{{ scope.row.yx_name ? '/'
              : '' }}{{ scope.row.class_name }}</span>
          </template>
        </el-table-column>

        <el-table-column v-for="(item, index) in sxqxArr" :key="index" :prop="'count_' + item.param_value"
          :label="item.param_desc" width="" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row['count_' + item.param_value] }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="count_people" label="总人数" width="" align="center"></el-table-column>
      </el-table>
      <el-divider v-if="false">按系部统计</el-divider>
      <el-table v-if="false" :data="yxTableData" border stripe style="margin-bottom: 20px;">
        <el-table-column prop="yx_name" label="系部" width="200"></el-table-column>
        <el-table-column v-for="(item, index) in sxqxArr" :prop="'count_' + item.param_value" :label="item.param_desc"
          width="" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row['count_' + item.param_value] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <div id="chartBox" style="height:600px"></div>
    </div>



  </div>
</template>

<script>
import util from "../../../utils/util.js"
import tchtree from '../../com/tchTree.vue'
import {njsyxx_statistics_export_excel} from "../../../../public/js/Export2Excel";

export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: '',
      sign_date: util.formatDate(new Date()),
      tableData: [],
      yxTableData: [],
      sxqxArr: []
    }
  },
  mounted() {
    // $("#chartBox").height($(window).height() - 220)
    this.getAllPickerVal().then(rr => {
      this.getMyClasses()
    })
  },
  methods: {
    handlePageChange(e) {
      this.$$parent(this, "page").then(page => {
        page.current_page = e
        this.$$parent(this, "page", page)
        this.$$parent(this, "getStudents")
      })
    },
    handleSizeChange(e) {

      this.$$parent(this, "page").then(page => {
        page.per_page = e
        this.$$parent(this, "page", page)
        this.$$parent(this, "getStudents")

      })

    },

    getAllPickerVal() {
      return new Promise(resolve => {
        //巡访形式&&巡访情况
        this.$http.post("/api/sys_param_list", { param_type: "'SHIXI_STATUS'" }).then((res) => {
          let sxqxArr = []
          res.data.map((item, index, arr) => {
            if (item.param_type == 'SHIXI_STATUS') {
              sxqxArr.push(item)
            }
          })
          this.sxqxArr = sxqxArr
          resolve()
        })
      })
    },
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        // this.class_id = this.myClasses[0].id
        this.getData()
      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.total_name == '合计') {
        return 'primary-row';
      }
      return '';
    },
    getData() {
      let results = []
      this.$http.post("/api/stu_sxqx_table", { class_id: this.class_id }).then(res => {
        res.data && res.data.data && res.data.data.map(r => {
          r.arr && r.arr.map(a => {
            r[`count_${a.name}`] = a.lable
          })
        })
        let classArr = JSON.parse(JSON.stringify(res.data.data))
        let yxsArr = JSON.parse(JSON.stringify(res.data.yx_data))

        let groupBYyx = this._lo.groupBy(classArr, "yx_name")
        yxsArr.map(a => {
          let obj = {}
          groupBYyx[a.yx_name].map(b => {
            for (let item in b) {
              if (typeof b[item] == 'number' || typeof b[item] == 'string') {
                if (!obj[item]) {
                  obj[item] = 0
                }
                obj[item] += Number(b[item])
              }
            }
          })
          obj.class_name = `合计`
          obj.yx_name = groupBYyx[a.yx_name][0].yx_name
          obj.total_name = '合计'
          groupBYyx[a.yx_name].push(obj)
        })

        for (let item in groupBYyx) {
          results = results.concat(groupBYyx[item])
        }

        this.tableData = results
        this.yxTableData = res.data.yx_data
        this.initChart()
      })
    },
    initChart() {
      let dataList = JSON.parse(JSON.stringify(this.tableData))

      let rate = dataList.map(e => {
        return (((e.count_sx + e.count_cj + e.count_sb + e.count_cg) * 10000 / e.count_people) / 100).toFixed(0)
      })
      //console.log(111, this.dataList)
      let dataAxis = this._lo.map(dataList, 'class_name')
      let data = rate
      // console.log(data)
      // console.log(dataAxis)

      let option = {
        title: {
          text: '班级/小组实习率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (a, b, c) {

            return dataAxis[a[0].dataIndex] + "<br/>实习率：" + a[0].value + "%"
          }
        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          splitLine: {
            show: false
          },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
            },
          }
        ],
      };
      let myChart = this.$echarts.init(document.getElementById('chartBox'));
      myChart.setOption(option)
    },
    exportSXQK() {
      this.$http.post('/api/export_statistics_xls', {
        class_id:this.class_id,
        type: 'sxqk'
      }).then(res => {
        let excelData = [
          ['班级实习情况汇总'],
          ['序号','系部','班级','实习','参军','升学/升本','出国','长病假','实习结束','未实习','总人数']
        ]
        res.data.data && res.data.data.map((r,index) => {
          excelData.push([
            ++index,
            r.yx_name,
            r.class_name,
            r.count_sx,
            r.count_cj,
            r.count_sb,
            r.count_cg,
            r.count_cbj,
            r.count_sxjs,
            r.count_wsx,
            r.count_people,
          ])
        })
        njsyxx_statistics_export_excel(excelData,11)
      })
    },
  },

};
</script>

<style>
.el-table .primary-row {
  background: #c6e2ff !important;
}
.el-table .primary-row .cell{
  color: #3469f3;
}
.el-table .el-table__body .primary-row:hover>td {
        background: #dcf0ff !important;
      }
</style>
