<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>班级或小组：</span>
        <!-- <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item,idx) in myClasses" :key="idx" filterable
                     :label="item.upname+'-'+item.name+(item.type=='bj'?'[班级]':'[小组]')" :value="item.id"></el-option>
        </el-select> -->
        <tchtree v-model="class_id" @change="getData"></tchtree>
        <el-button v-if="$store.state.pmid == 221" type="primary" size="mini" icon="el-icon-download" @click="exportDuikou">导出</el-button>
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生实习</el-breadcrumb-item>
          <el-breadcrumb-item>对口率统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <el-table v-tableFit :data="tableData" style="width: 100%;margin-bottom: 20px;" height="50vh" border
        :row-class-name="tableRowClassName">
        <el-table-column prop="stu_name" label="班级" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.yx_name }}{{ scope.row.yx_name ? '/' : '' }}{{
                scope.row.NAME }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qb_count" label="实习人数" width="" align="center"></el-table-column>
        <el-table-column prop="dk_count" label="对口人数" width="" align="center"></el-table-column>
        <el-table-column prop="dk_rate" label="对口率" width="" align="center"></el-table-column>
      </el-table>
      <el-divider>按系部统计</el-divider>
      <el-table :data="yxTableData" border stripe style="margin-bottom: 20px;">
        <el-table-column prop="yx_name" label="系部" width="200"></el-table-column>
        <el-table-column prop="qb_count" label="实习人数" width="" align="center"></el-table-column>
        <el-table-column prop="dk_count" label="对口人数" width="" align="center"></el-table-column>
        <el-table-column prop="dk_rate" label="对口率" width="" align="center"></el-table-column>
      </el-table>

      <div id="chartBox" style="height:600px"></div>

    </div>



  </div>
</template>

<script>
import util from "../../../utils/util.js"
import tchtree from '../../com/tchTree.vue'
import {njsyxx_statistics_export_excel} from "../../../../public/js/Export2Excel";
export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: '',
      sign_date: util.formatDate(new Date()),
      tableData: [],
      yxTableData: [],
    }
  },
  mounted() {
    // $("#chartBox").height($(window).height() - 220)
    this.getMyClasses()



  },
  methods: {
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        // this.class_id = this.myClasses[0].id
        this.getData()
      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.total_name == '合计') {
        return 'primary-row';
      }
      return '';
    },
    getData() {
      let results = []

      this.$http.post("/api/stu_is_ok_statistics", { dk_date: this.sign_date, class_id: this.class_id }).then(res => {
        res.data && res.data.data && res.data.data.map(a => {

          if (a.dk_rate) {
            a.dk_rate = this.$keepTwoDecimal(parseFloat(a.dk_rate) * 100).toFixed(0) 
          }
        })
        res.data.yx_data.map(y => {
          if (y.dk_rate) {
            y.dk_rate = this.$keepTwoDecimal(parseFloat(y.dk_rate) * 100).toFixed(0) 
          }
        })
        let classArr = JSON.parse(JSON.stringify(res.data.data))
        let yxsArr = JSON.parse(JSON.stringify(res.data.yx_data))

        let groupBYyx = this._lo.groupBy(classArr, "yx_name")
        yxsArr.map(a => {
          let obj = {}
          groupBYyx[a.yx_name].map(b => {
            for (let item in b) {
              if (typeof b[item] == 'number' || typeof b[item] == 'string') {
                if (!obj[item]) {
                  obj[item] = 0
                }else{ obj[item] = Number(obj[item]) }
                obj[item] += Number(b[item])
              }
            }
            b.dk_rate+='%'
          })
          obj.NAME = `合计`
          obj.yx_name = groupBYyx[a.yx_name][0].yx_name
          obj.total_name = '合计'
          if (obj.dk_rate>100) obj.dk_rate=100
          obj.dk_rate += '%'
          groupBYyx[a.yx_name].push(obj)
        })

        for (let item in groupBYyx) {
          results = results.concat(groupBYyx[item])
        }

        this.tableData = results
        this.yxTableData = res.data.yx_data
        this.initChart()
      })
    },
    initChart() {
      let dataList = JSON.parse(JSON.stringify(this.tableData))
      console.log(dataList)
      dataList.map(e => { e.dk_rate = parseFloat(e.dk_rate.replace('%', '')) })
      //console.log(111, this.dataList)
      let dataAxis = this._lo.map(dataList, 'NAME')
      let data = this._lo.map(dataList, 'dk_rate')


      let option = {
        title: {
          text: '班级/小组对口率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (a, b, c) {

            return dataAxis[a[0].dataIndex] + "<br/>对口率：" + a[0].value + "%"
          }
        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          splitLine: {
            show: false
          },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
            },
          }
        ],
      };


      let myChart = this.$echarts.init(document.getElementById('chartBox'));
      myChart.setOption(option)
    },
    exportDuikou() {
      this.$http.post('/api/export_statistics_xls', {
        class_id:this.class_id,
        type: 'duikou'
      }).then(res => {
        let excelData = [
          ['班级对口率汇总'],
          ['序号','系部','班级','实习人数','对口人数','对口率']
        ]
        res.data.data && res.data.data.map((r,index) => {
          if (r.dk_rate) {
            r.dk_rate = this.$keepTwoDecimal(parseFloat(r.dk_rate) * 100).toFixed(0)
          }
          excelData.push([
            ++index,
            r.yx_name,
            r.NAME,
            r.qb_count,
            r.dk_count,
            r.dk_rate + "%",
          ])
        })
        njsyxx_statistics_export_excel(excelData,6)
      })
    },
  },

};
</script>

