<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>班级或小组：</span>
        <!-- <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item,idx) in myClasses" :key="idx" filterable
                     :label="item.upname+'-'+item.name+(item.type=='bj'?'[班级]':'[小组]')" :value="item.id"></el-option>
        </el-select> -->
        <tchtree v-model="class_id" @change="getData"></tchtree>
        <el-button v-if="$store.state.pmid == 221" type="primary" size="mini" icon="el-icon-download" @click="exportDQ">导出</el-button>
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生实习</el-breadcrumb-item>
          <el-breadcrumb-item>地区分布统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <div id="dqchartBox"></div>


      <div id="dqchartBox2"></div>

    </div>

  </div>
</template>

<script>
import util from "../../../utils/util.js"
import arae_value from "../../../utils/arae_value.js"
import tchtree from '../../com/tchTree.vue'
import {njsyxx_statistics_export_excel} from "../../../../public/js/Export2Excel";
export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: '',
      sign_date: util.formatDate(new Date()),
      dataList: [],
    }
  },
  mounted() {
    $("#dqchartBox").height($(window).height() - 220)
    $("#dqchartBox2").height($(window).height() - 220)
    this.getMyClasses()



  },
  methods: {
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        // this.class_id = this.myClasses[0].id
        this.getData()
      })
    },

    deepFind(list, iid) {
      for (let o of list || []) {
        if (o.value == iid) return o
        const o_ = this.deepFind(o.children, iid)
        if (o_) return o_
      }
    },

    getData() {
      this.$http.post("/api/stu_shi_code_statistics", { dk_date: this.sign_date, class_id: this.class_id }).then(res => {
        res.data && res.data.map(r => {
          if (r.shi_code) {

            let obj = this.deepFind(arae_value, r.shi_code)
            r.shi_code_name = obj ? obj.label : 'r.shi_code'
            //console.log(69,r.shi_code, r.shi_code_name)
          }
        })
        //合并相同城市
        let citys = {}, cityArr = [];
        for (let item of res.data) {
          if (!citys[item.shi_code_name] && item.shi_code_name != undefined) {
            citys[item.shi_code_name] = item
            cityArr.push(item)
          } else {
            if (item.shi_code_name != undefined) {
              citys[item.shi_code_name].count_num += item.count_num
            }

          }
        }

        cityArr = cityArr.sort((a, b) => { return b.count_num - a.count_num })
        this.dataList = cityArr
        this.initChart()
      })
    },
    initChart() {
      //console.log(111, this.dataList)
      let dataAxis = this._lo.map(this.dataList, 'shi_code_name')
      let data = this._lo.map(this.dataList, 'count_num')


      let option = {
        title: {
          text: '班级/小组地区分布人数(人)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          min: 0,//最小值

          splitNumber: 10,
          axisLine: { show: true },
          axisTick: { show: true },
          splitLine: {
            show: false
          },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('dqchartBox'));
      myChart.setOption(option)
      this.initChart2(dataAxis, data)
    },
    initChart2(xdata, vdata) {
      let option = {
        title: {
          text: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //     top: "10%",
        //     left: 'center'
        // },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['30%', '50%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              normal: {
                formatter: '{b}{c}人({d}%)',
                textStyle: {
                  fontWeight: 'normal',
                  fontSize: 12
                }
              }
            },
            data: [

            ],
            color: [
              '#1598ED', '#45BDFF',
              '#37A2DA', '#32c5e9', '#67E0E3', '#9FE6B8',
              '#7827e6', '#8d39ec', '#aa4ff6', '#ea80fc',
              '#c848b9', '#f962a7', '#fd836d', '#f7ba79',
              '#028174', '#0ab68b', '#92de88', '#ffe3b3',
              '#294D99', '#156ACF',
              // '#33a9ff', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      let _xdata = []
      for (let i = 0; i < xdata.length; i++) {
        _xdata.push({ value: vdata[i], name: xdata[i] })
      }
      // if(_xdata.length>6){
      //   _xdata=_xdata.splice(0,6)
      // }

      option.series[0].name = "实习地区"
      option.series[0].data = _xdata;
      option.title.text = "实习地区分布比例"
      this.$echarts.init(document.getElementById('dqchartBox2')).setOption(option);
    },
    exportDQ() {
      this.$http.post('/api/export_statistics_xls', {
        class_id:this.class_id,
        type: 'dq'
      }).then(res => {
        let excelData = [
          ['班级地区分布人数汇总'],
          ['序号','系部','班级','地区','人数']
        ]

        res.data && res.data.map(r => {
          if (r.shi_code) {

            let obj = this.deepFind(arae_value, r.shi_code)
            r.shi_code_name = obj ? obj.label : 'r.shi_code'
            //console.log(69,r.shi_code, r.shi_code_name)
          }
        })
        //合并相同城市
        let citys = {}, cityArr = [];
        for (let item of res.data) {
          if (!citys[item.shi_code_name] && item.shi_code_name != undefined) {
            citys[item.shi_code_name] = item
            cityArr.push(item)
          } else {
            if (item.shi_code_name != undefined) {
              citys[item.shi_code_name].count_num += item.count_num
            }
          }
        }
        cityArr = cityArr.sort((a, b) => { return b.count_num - a.count_num })
        cityArr.map((r,index) => {
          excelData.push([
            ++index,
            r.yx_name,
            r.NAME,
            r.shi_code_name,
            r.count_num,
          ])
        })
        njsyxx_statistics_export_excel(excelData,5)
      })
    },
  },

};
</script>

