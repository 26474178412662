<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>班级或小组：</span>
        <!-- <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item,idx) in myClasses" :key="idx" filterable
                     :label="item.upname+'-'+item.name+(item.type=='bj'?'[班级]':'[小组]')" :value="item.id"></el-option>
        </el-select> -->
        <tchtree v-model="class_id" @change="getData"></tchtree>
        <el-button v-if="$store.state.pmid == 221 " type="primary" size="mini" icon="el-icon-download" @click="exportXZ">导出</el-button>
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生实习</el-breadcrumb-item>
          <el-breadcrumb-item>薪资范围统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <el-table v-tableFit :data="tableData" style="width: 100%;margin-bottom: 20px;" height="50vh" border
        :row-class-name="tableRowClassName">
        <el-table-column prop="stu_name" label="班级" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.yx_name }}{{ scope.row.yx_name
              ? '/' : '' }}{{ scope.row.class_name }}</span>
          </template>
        </el-table-column>

        <el-table-column v-for="(item, index) in dictArr" :key="index" :prop="'count_' + item.param_value"
          :label="item.param_desc" width="" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row['count_' + item.param_value] }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="count_people" label="总人数" width="" align="center"></el-table-column>
      </el-table>
      <el-divider v-if="false">按系部统计</el-divider>
      <el-table v-if="false" :data="yxTableData" border stripe style="margin-bottom: 20px;">
        <el-table-column prop="yx_name" label="系部" width="200"></el-table-column>
        <el-table-column v-for="(item, index) in dictArr" :prop="'count_' + item.param_value" :label="item.param_desc"
          width="" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row['count_' + item.param_value] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="count_people" label="总人数" width="" align="center"></el-table-column>
      </el-table>

      <div id="chartBox" style="height:600px"></div>

    </div>



  </div>
</template>

<script>
import util from "../../../utils/util.js"
import tchtree from '../../com/tchTree.vue'
import {njsyxx_statistics_export_excel} from "../../../../public/js/Export2Excel";
export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: '',
      sign_date: util.formatDate(new Date()),
      tableData: [],
      yxTableData: [],
      dictArr: [],
    }
  },
  mounted() {
    // $("#chartBox").height($(window).height() - 220)
    this.getAllPickerVal().then(rr => {
      this.getMyClasses()
    })


  },
  methods: {
    handlePageChange(e) {
      this.$$parent(this, "page").then(page => {
        page.current_page = e
        this.$$parent(this, "page", page)
        this.$$parent(this, "getStudents")

      })

    },
    handleSizeChange(e) {

      this.$$parent(this, "page").then(page => {
        page.per_page = e
        this.$$parent(this, "page", page)
        this.$$parent(this, "getStudents")

      })

    },
    getAllPickerVal() {
      return new Promise(resolve => {
        //巡访形式&&巡访情况
        this.$http.post("/api/sys_param_list", { param_type: "'SALARY_RANGE'" }).then((res) => {
          let dictArr = []
          res.data.map((item, index, arr) => {
            if (item.param_type == 'SALARY_RANGE') {
              dictArr.push(item)
            }
          })
          this.dictArr = dictArr
          resolve()
        })
      })
    },
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        // this.class_id = this.myClasses[0].id
        this.getData()
      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.total_name == '合计') {
        return 'primary-row';
      }
      return '';
    },
    getData() {
      let results = []

      this.$http.post("/api/stu_salary_statistics", { dk_date: this.sign_date, class_id: this.class_id }).then(res => {
        res.data && res.data.data && res.data.data.map(r => {
          r.arr && r.arr.map(a => {
            r[`count_${a.name}`] = a.lable

          })
        })

        let classArr = JSON.parse(JSON.stringify(res.data.data))
        let yxsArr = JSON.parse(JSON.stringify(res.data.yx_data))

        let groupBYyx = this._lo.groupBy(classArr, "yx_name")
        yxsArr.map(a => {
          let obj = {}
          groupBYyx[a.yx_name].map(b => {
            for (let item in b) {
              if (typeof b[item] == 'number' || typeof b[item] == 'string') {
                if (!obj[item]) {
                  obj[item] = 0
                }
                obj[item] += Number(b[item])
              }
            }
          })
          obj.class_name = `合计`
          obj.yx_name = groupBYyx[a.yx_name][0].yx_name
          obj.total_name = '合计'
          groupBYyx[a.yx_name].push(obj)
        })

        for (let item in groupBYyx) {
          results = results.concat(groupBYyx[item])
        }

        this.tableData = results
        this.yxTableData = res.data.yx_data
        this.initChart()
      })
    },
    initChart() {
      let xData = [{ name: "未知", value: 0 }, { name: "2000以下", value: 0 }, { name: "2000-3000", value: 0 }, { name: "3000-4000", value: 0 }, { name: "4000-5000", value: 0 }, { name: "5000以上", value: 0 }]
      for (let item of this.tableData) {
        xData[0].value += item.count_0
        xData[1].value += item.count_1
        xData[2].value += item.count_2
        xData[3].value += item.count_3
        xData[4].value += item.count_4
        xData[5].value += item.count_5
      }
      let option = {
        title: {
          text: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //     top: "10%",
        //     left: 'center'
        // },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['30%', '50%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              normal: {
                formatter: '{b}{c}人({d}%)',
                textStyle: {
                  fontWeight: 'normal',
                  fontSize: 12
                }
              }
            },
            data: [

            ],
            color: [
              '#1598ED', '#45BDFF',
              '#37A2DA', '#32c5e9', '#67E0E3', '#9FE6B8',
              '#7827e6', '#8d39ec', '#aa4ff6', '#ea80fc',
              '#c848b9', '#f962a7', '#fd836d', '#f7ba79',
              '#028174', '#0ab68b', '#92de88', '#ffe3b3',
              '#294D99', '#156ACF',
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };


      option.series[0].name = "薪资范围"
      option.series[0].data = xData;
      option.title.text = "薪资范围分布比例"
      this.$echarts.init(document.getElementById('chartBox')).setOption(option);
    },
    exportXZ() {
      this.$http.post('/api/export_statistics_xls', {
        class_id:this.class_id,
        type: 'xinzi'
      }).then(res => {
        let excelData = [
          ['班级薪资范围人数汇总'],
          ['序号','系部','班级','未知','2000以下','2000-3000','3000-4000','4000-5000','5000以上','总人数']
        ]
        res.data.data && res.data.data.map((r,index) => {
          excelData.push([
            ++index,
            r.yx_name,
            r.class_name,
            r.count_0,
            r.count_1,
            r.count_2,
            r.count_3,
            r.count_4,
            r.count_5,
            r.count_people,
          ])
        })
        njsyxx_statistics_export_excel(excelData,10)
      })
    },
  },

};
</script>

